<template>
  <DgRivals :value="widget.data || {}" :showItemWithError="false" />
</template>
<script>
import MixinFixWidget from "@/mixins/fixWidget";
import { DgRivals } from "@engenharia/dashgoo-widgets";

export default {
  name: "RenderWidgetRivals",
  mixins: [MixinFixWidget],
  components: {
    DgRivals,
  },
};
</script>
